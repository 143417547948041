import style from '../style/CalculateHost.module.scss';
import {Fragment, useState} from "react";
import ActionBar from "~/layout/ActionBar";
import className from "classnames/bind";
import InputPriceField from "../../../components/Forms/InputPriceField/InputPriceField";
const cn = className.bind(style);
function CalculateHost() {

    const [post, setPost] = useState(0);

    const [products, setProducts] = useState(0);

    const [image, setImage] = useState(0);

    const [host, setHost] = useState(0);

    const setValue = (value, input) => {

        let postCalculate = post;
        let productsCalculate = products;
        let imageCalculate = image;

        value = value*1;

        if(input === 'post')
        {
            postCalculate = value;
            setPost(value);
        }
        if(input === 'products')
        {
            productsCalculate = value;
            setProducts(value);
        }
        if(input === 'image')
        {
            imageCalculate = value;
            setImage(value);
        }

        calculateHost({ post: postCalculate, products : productsCalculate, image: imageCalculate })
    };

    const calculateHost = ({ post, products, image}) => {

        let totalPost = post + products;

        let hostCalculated = 0;

        if(totalPost <= 10) {
            hostCalculated = 2
        }
        else if(10 < totalPost && totalPost <= 20) {
            hostCalculated = 3
        }
        else if(20 < totalPost && totalPost <= 50) {
            hostCalculated = 4
        }
        else if(50 < totalPost && totalPost <= 80) {
            hostCalculated = 5
        }
        else if(80 < totalPost && totalPost <= 100) {
            hostCalculated = 6
        }
        else if(100 < totalPost && totalPost <= 150) {
            hostCalculated = 7
        }
        else if(150 < totalPost && totalPost <= 170) {
            hostCalculated = 8
        }
        else if(170 < totalPost && totalPost <= 200) {
            hostCalculated = 9
        }
        else if(200 < totalPost && totalPost <= 250) {
            hostCalculated = 10
        }
        else if(250 < totalPost && totalPost <= 300) {
            hostCalculated = 15
        }
        else {
            hostCalculated = 20
        }

        let imageGb = (image*0.015)*totalPost + totalPost*0.001

        if(imageGb > hostCalculated)
        {
            hostCalculated = Math.ceil(imageGb);
        }

        setHost(hostCalculated)
    };

    return (
        <Fragment>
            <ActionBar title={'Dự toán host'}>
            </ActionBar>
            <div className="container">
                <div className={cn('wrapper', 'form')}>
                    <div className="content pd-2 mb-2">
                        <p className={cn('check-domain-title-mini', 'mb-2')}>Nhập thông tin để kiểm tra</p>
                        <div className={cn('check-domain-wrapper')}>
                            <InputPriceField label={'Số lượng bài viết'} value={post} onChange={(value) => setValue(value, 'post')} />
                            <InputPriceField label={'Số lượng sản phẩm'} value={products} onChange={(value) => setValue(value, 'products')} />
                            <InputPriceField label={'Số lượng hình ảnh trung bình mỗi bài viết (sản phẩm)'} value={image} onChange={(value) => setValue(value, 'image')} />
                        </div>
                    </div>

                    {
                        (host !== 0) && <div className="content pd-2">
                            <h3 className={cn('check-domain-title-big', 'green', 'mb-1')}>Host {host} GB,</h3>
                            <p className={cn('check-domain-title-mini', 'mt-1')}>
                                Bạn nên sử dụng gói host {host} GB hoặc cao hơn để đảm bảo website hoạt động ổn định
                            </p>
                        </div>
                    }

                </div>
            </div>
        </Fragment>
    )
}

export default CalculateHost;