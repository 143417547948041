import {Tooltip} from "antd";
import {Button, Icon} from "~/components";
import {
	useCan
} from "~/hooks";

function SaleCommitAction({item, setItemEdit, openModal}) {

	const useEdit   = useCan('salesCommitEdit')

	const useDelete = useCan('salesCommitDelete')

	const handleEditClick = (item) => {
		setItemEdit(item);
		openModal('addEdit');
	}

	const handleDeleteClick = (item) => {
		setItemEdit(item);
		openModal('delete');
	}

	const handleConfirmClick = (item) => {
		setItemEdit(item);
		openModal('confirm');
	}

	return (
		<div className="table-action d-flex">
			{useEdit && <Tooltip title="Cập nhật"><Button background blue leftIcon={Icon.edit} onClick={() => handleEditClick(item)}></Button></Tooltip>}
			{useDelete && <Tooltip title="Xóa cam kết"><Button background red leftIcon={Icon.delete} onClick={() => handleDeleteClick(item)}></Button></Tooltip>}
			<Tooltip title="Xác nhận cam kết"><Button background green leftIcon={Icon.success} onClick={() => handleConfirmClick(item)}></Button></Tooltip>
		</div>
	)
}

export default SaleCommitAction;