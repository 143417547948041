import request from "~/utils/http";

const utilitiesApi = {
	gets : async (params) => {
		const url = 'utilities';
		return await request.get(url, {params});
	},
	ranks : async (params) => {
		const url = 'utilities/ranks';
		return await request.get(url, {params});
	},

	checkDomain : async (domain) => {
		const url = `utilities/check-domain`;
		return await request.post(url, {
			domain: domain
		});
	},

	whoisDomain : async (domain) => {
		const url = `utilities/whois-domain`;
		return await request.post(url, {
			domain: domain
		});
	},
};

export default utilitiesApi;