import style from '../style/CheckDomain.module.scss';
import {Fragment, useState} from "react";
import ActionBar from "~/layout/ActionBar";
import _ from "lodash";
import {
    Loading,
    Button,
    Modal
} from "~/components";
import {
    apiError,
    handleRequest
} from "~/utils";
import className from "classnames/bind";
import FontAwesome from "../../../components/FontAwesome";
import {utilitiesApi} from "../../../api";
import DomainWhois from "../components/Modal/DomainWhois";
const cn = className.bind(style);
function CheckDomain() {

    const [domain, setDomain] = useState("");

    const [domainRender, setDomainRender] = useState("");

    const [suggestsDomain, setSuggestsDomain] = useState([]);

    const [domainPrice, setDomainPrice] = useState("");

    const [viewWhois, setViewWhois] = useState(true);

    const [status, setStatus] = useState(null);

    const [loading, setLoading] = useState(false);

    const [openModal, setOpenModal] = useState({
        whois: false,
    });

    const handleEvent = {
        openModal: (modal) => {
            openModal[modal] = true;
            setOpenModal({...openModal})
        },
        closeModal: (modal) => {
            openModal[modal] = false;
            setOpenModal({...openModal});
        },
    }

    const checkDomain = async () => {

        if (!domain.trim()) return;

        setLoading(true);

        setViewWhois(false);

        setStatus(null);

        setSuggestsDomain([]);

        setDomainPrice("");

        setDomainRender("");

        let [error, response] = await handleRequest(utilitiesApi.checkDomain(domain));

        let message = apiError(`Kiểm tra tên miền không thành công`, error, response);

        if(!message)
        {
            setSuggestsDomain(response.data.suggests);
            setStatus(response.data.check);
            setDomainPrice(response.data.price);
            setDomainRender(response.data.domain);
            if(response.data.isManager === 0)
            {
                setViewWhois(true);
            }
        }

        setLoading(false);
    };

    return (
        <Fragment>
            <ActionBar title={'Kiểm tra tên miền'}>
            </ActionBar>
            <div className="container">
                <div className={cn('wrapper')}>
                    <div className="content pd-2 mb-2">
                        <p className={cn('check-domain-title-mini', 'mb-2')}>Nhập tên miền cần kiểm tra</p>
                        <div className={cn('check-domain-wrapper')}>
                            <FontAwesome icon={'fas fa-globe'} className={cn('check-domain-icon')}></FontAwesome>
                            <input value={domain}
                                   onChange={(e) => setDomain(e.target.value)}
                                   placeholder="hãy gõ tên miền bạn muốn vào đây"
                                   autoCapitalize="none"
                                   required
                                   type="text"
                            />
                            <span className={cn('check-domain-button')}>
                                <button type="submit" className={cn('button-submit')} onClick={checkDomain}>
                                    <FontAwesome icon={'fas fa-search'}></FontAwesome>
                                </button>
                            </span>
                        </div>
                    </div>

                    {loading && <Loading/>}

                    {
                        (status === 'success') && <div className="content pd-2">
                            <h3 className={cn('check-domain-title-big', 'green', 'mb-1')}>Chúc mừng!,</h3>
                            <p className={cn('check-domain-title-mini', 'mt-1')}>
                                Bạn có thể mua tên miền <b>{domain}</b> với giá <span className={cn('domain-price-money')}>{domainPrice} đ</span>
                            </p>
                        </div>
                    }

                    {
                        (status === 'error') && <div className={cn('domain-card', 'content', 'pd-2')}>
                            <h3 className={cn('check-domain-title-big', 'mb-1')}>Rất tiếc,</h3>
                            <div className={cn('domain-price', 'mb-1')}>
                                <div>
                                    <span className={cn('domain-price-text')}>Tên miền <b>{domainRender}</b> đã có người mua.</span>
                                </div>
                                {
                                    viewWhois && <Button background red onClick={() => handleEvent.openModal('whois')}>Xem Whois</Button>
                                }
                            </div>
                        </div>
                    }

                    {
                        (!_.isEmpty(suggestsDomain)) && Object.values(suggestsDomain).map((item) => {
                            return <div className={cn('domain-card', 'content', 'pd-2')} key={item?.extension}>
                                <p className={cn('domain-title', 'mb-1')}>{item?.domain}<span>{item?.extension}</span></p>
                                <div className={cn('domain-price', 'mb-1')}>
                                    <div>
                                        <span className={cn('domain-price-money')}>{item?.price} đ</span>
                                        <span className={cn('domain-price-text')}> cho năm đầu tiên</span>
                                    </div>
                                    {
                                        item.check === 'success' &&
                                        <span className={cn('domain-price-status', 'success')}>Có thể mua</span>
                                    }
                                    {
                                        item.check === 'error' &&
                                        <span className={cn('domain-price-status', 'error')}>Không thể mua</span>
                                    }

                                </div>
                            </div>
                        })
                    }

                </div>
            </div>
            {
                (domainRender && openModal.whois) &&
                <Modal title={"Thông tin tên miền " + domainRender} visible={openModal.whois} onCancel={() => {handleEvent.closeModal('whois')}}>
                    <DomainWhois domain={domainRender} />
                </Modal>
            }
        </Fragment>
    )
}

export default CheckDomain;