import {useCallback, useMemo, useState} from "react";
import {
	DateRangeField,
	SelectField
} from "~/components/Forms";
import {strToTime} from "~/utils";
import StatusLabel from "~/components/StatusLabel";
import {
	useUtilities,
	useCurrentUser
} from "~/hooks";

function SaleEventSearch({listGroup, onChange}) {

	const {utilities} = useUtilities();

	const currentUser 		= useCurrentUser();

	const listViews = [
		{value: 'groups', label: 'Xem theo nhóm'},
		{value: 'users', label: 'Xem theo nhân viên'},
	];

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả nhóm' }, ...Object.entries(listGroup).map(([key, item]) => {
			return {value : item.id, label : item.name};
		})];
	}, [listGroup]);

	let [listPosition, setListPosition] = useState(utilities.roles);

	listPosition = Object.keys(utilities.roles).filter((key) => {
		return utilities.roles[key].department == currentUser.department;
	}).reduce((obj, key) => {
		obj[key] = utilities.roles[key];
		return obj;
	}, {});

	const positionOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(listPosition).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.title}</StatusLabel>
			};
		})];
	}, [listPosition]);

	const handleTypeChange = useCallback((value) => {
		if(onChange) onChange('type', value);
	}, [onChange]);

	const handleTimeChange = useCallback((value) => {
		let valueNew = [...value]
		valueNew[0] = strToTime(valueNew[0]);
		valueNew[1] = strToTime(valueNew[1]);
		if(onChange) onChange('time', valueNew);
	}, [onChange]);

	const handleGroupChange = useCallback((value) => {
		if(onChange) onChange('group', value);
	}, [onChange]);

	const handleRoleChange = useCallback((value) => {
		if(onChange) onChange('role', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<form className="form" autoComplete="off">
				<SelectField placeholder="Trạng thái" options={listViews} onChange={(value) => handleTypeChange(value)}></SelectField>
				<DateRangeField name="time" onChange={(value) => handleTimeChange(value)} />
				<SelectField placeholder="Chọn nhóm" options={groupOptions} onChange={(value) => handleGroupChange(value)} />
				<SelectField name="role" placeholder="Chọn chức vụ" options={positionOptions} onChange={(value, event) => handleRoleChange(value)} />
			</form>
		</div>
	)
}

export default SaleEventSearch;