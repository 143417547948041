import {Fragment, useState, useEffect} from "react";
import {
    Loading,
} from "~/components";
import {
    apiError,
    handleRequest
} from "~/utils";
import {
    utilitiesApi
} from "~/api";
import DOMPurify from "dompurify";
function WhoisDomain({domain}) {

    const [domainWhois, setDomainWhois] = useState("");

    const [loading, setLoading] = useState(false);

    const getWhois = async () => {

        setDomainWhois("");

        setLoading(true);

        let [error, response] = await handleRequest(utilitiesApi.whoisDomain(domain));

        let message = apiError(`Kiểm tra tên miền không thành công`, error, response);

        if(!message)
        {
            setDomainWhois(response.data.whois);
        }

        setLoading(false);
    }

    useEffect(function () {
        getWhois()
    }, [domain])

    return (
        <Fragment>
            { loading && <Loading/>}
            {domainWhois && <div style={{wordBreak: 'break-all'}}
                                 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(domainWhois)}}/> }

        </Fragment>
    )
}

export default WhoisDomain;