import {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import {
	Button,
	Loading,
	Modal,
	Icon
} from "~/components";
import ActionBar from "~/layout/ActionBar";
import {
	saleCommitActions,
	saleCommitErrorSelector,
	saleCommitFilterSelector,
	saleCommitItemsSelector,
	saleCommitLoadingSelector,
	saleCommitPaginationSelector
} from "../saleCommitSlice";
import {
	apiError,
	handleRequest,
} from "~/utils";
import {
	useCan,
	useDevice,
	useGroup
} from "~/hooks";
import {
	saleApi
} from "~/api";
import {
	SaleCommitTable,
	CommitSearchBar,
	SaleCommitFormAddEdit,
	CommitSearchMobile,
	SaleCommitConfirm
} from "../components";

function SaleCommit() {

	const listGroup = useGroup();

	const {isMobile} = useDevice();

	const canAdd = useCan('salesCommitAdd');

	const canConfirm = useCan('salesCommitConfirm');

	const canEdit = useCan('salesCommitEdit');

	const dispatch  = useDispatch();

	const items     = useSelector(saleCommitItemsSelector);

	const loading   = useSelector(saleCommitLoadingSelector);

	const error     = useSelector(saleCommitErrorSelector);

	const pagination = useSelector(saleCommitPaginationSelector);

	const filter    = useSelector(saleCommitFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	//Model
	const [openModal, setOpenModal] = useState({
		addEdit     : false,
		delete      : false,
		cancel      : false,
		start       : false,
		end         : false,
		assign      : false,
		report      : false,
		confirm     : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	useEffect(() => {
		handleLoading()
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleLoading = () => {
		dispatch(saleCommitActions.fetchData(filter));
	}

	//Submit
	const handleSaveItem = async (data, item) => {
		let messageAction = 'Thêm mới';
		if(item?.id) {
			if(!canEdit) {
				notification.error({message: 'Thất bại', description: `Bạn không có quyền sử dụng chức năng này`});
				return;
			}
			data.id = item.id;
			messageAction = 'Cập nhật';
		}
		else {
			if(!canAdd) {
				notification.error({message: 'Thất bại', description: `Bạn không có quyền sử dụng chức năng này`});
				return;
			}
		}
		if (!isNaN(data.timeStart)) {
			data.timeStart = Math.round(new Date(+data.timeStart).getTime() / 1000)
		} else if (data.timeStart.search('GMT') != -1) {
			data.timeStart = Math.round(new Date(data.timeStart).getTime() / 1000)
		}
		let [error, response] = (item?.id) ? await handleRequest(saleApi.commitUpdate(data)) : await handleRequest(saleApi.commitAdd(data));
		let message = apiError(`${messageAction} cam kết thất bại`, error, response);
		if(!message) {
			if(!response?.data?.id) {
				notification.error({message: 'Thất bại', description: `Chưa thêm được cam kết`});
			}
			else {
				notification.success({message: 'Thành công', description: `${messageAction} cam kết thành công`});
				if(!item?.id) {
					dispatch(saleCommitActions.add(response.data));
				}
				else {
					dispatch(saleCommitActions.update(response.data));
				}
				handleModalClose('addEdit')
			}
		}
	}

	//Confirm
	const handleConfirmItem = async (item, setLoading) => {

		if(!item?.id)
		{
			notification.error({message: 'Thất bại', description: `Bạn không có quyền sử dụng chức năng này`});
			return;
		}
		let [error, response] = await handleRequest(saleApi.commitConfirm({
			id: item.id
		}));

		let message = apiError(`Xác nhận cam kết thất bại`, error, response);

		if(!message)
		{
			notification.success({message: 'Thành công', description: `Xác nhận cam kết thành công`});
			dispatch(saleCommitActions.update(response.data));
			handleModalClose('confirm')
		}

		setLoading(false);
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin cam kết để xóa'});
			return;
		}
		let [error, response] = await handleRequest(saleApi.eventDelete(itemEdit.id));
		let message = apiError(`xóa cam kết thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa cam kết thành công`});
			dispatch(saleCommitActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	//Search
	const SearchBar = (isMobile) ? CommitSearchMobile : CommitSearchBar;

	const handlePaginationChange = (page) => {
		dispatch(saleCommitActions.setFilter({...filter, page }));
	}

	const handleFilterChange = (newFilter) => {
		dispatch(saleCommitActions.setFilter(newFilter));
	};

	return (
		<Fragment>
			<ActionBar title={'Cam kết'}>
				{canAdd && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({});handleModalOpen('addEdit')}}>Thêm mới</Button>}
				<Button outline onClick={handleLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					<SearchBar filter={filter} onChange={handleFilterChange} listGroup={listGroup} />
					{items && <SaleCommitTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
						canConfirm={canConfirm}
					/>}
				</div>
			</div>
			{
				(canAdd || canEdit) &&
				<Modal title="Cam kết" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
					<SaleCommitFormAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} />
				</Modal>
			}

			{itemEdit?.id &&
				<Modal title="Xóa lịch hẹn" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
					<p className="mt-2">Bạn muốn xóa cam kết <b>{itemEdit?.name}</b>?</p>
					<div className="pd-1 d-flex justify-content-end gap modal-bottom">
						<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
						<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
					</div>
				</Modal>
			}

			{
				itemEdit?.id &&
				<Modal title="Xác nhận cam kết" visible={openModal.confirm} onCancel={() => {handleModalClose('confirm')}}>
					<SaleCommitConfirm item={itemEdit} onHandleSubmit={handleConfirmItem} />
				</Modal>
			}
		</Fragment>
	)
}

export default SaleCommit;