import {useEffect, useState} from "react";
import Button from "~/components/Button";
import _ from "lodash";

function SaleCommitConfirm({item, onHandleSubmit})
{
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (item?.id) {
        }
    }, [item]);

    return (
        <div className="form">

            {
                (!_.isEmpty(item.confirms)) && Object.values(item.confirms).map((confirm) => {
                    return <div className={'content pd-2'} key={confirm?.id}>
                        <p><b>{confirm?.user.firstname + ' ' + confirm?.user.lastname}</b></p>
                        <p>Xác nhận lúc {confirm?.created}</p>
                    </div>
                })
            }
            <hr />
            {
                !item.isConfirm && <div className="form-group d-flex justify-content_end mt-2">
                    <Button primary type="submit" loading={loading} onClick={() => onHandleSubmit(item, setLoading)}>Xác
                        nhận cam kết</Button>
                </div>
            }
        </div>
    )
}

export default SaleCommitConfirm;