import style from '../Home.module.scss';
import className from 'classnames/bind';
import {
	Image
} from "~/components";
import images from "~/assets/images";
import {Link} from "react-router-dom";
import {useCan} from "../../../hooks";
const cn = className.bind(style);

function ContentHeader({user}) {

	// Tạo một đối tượng Date mới
	const currentDate = new Date();

	// Tạo một mảng chứa các tên thứ trong tuần
	const daysOfWeek = ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'];

	// Lấy tên thứ của ngày hiện tại
	const currentDayOfWeek = daysOfWeek[currentDate.getDay()];

	// Lấy ngày hiện tại
	const currentDayOfMonth = currentDate.getDate();

	// Lấy tên tháng hiện tại
	const currentMonthName = new Intl.DateTimeFormat('vi-VN', { month: 'long' }).format(currentDate);

	// Lấy năm hiện tại
	const currentYear = currentDate.getFullYear();

	// Kết hợp các giá trị này để tạo ra chuỗi thời gian đầy đủ
	const fullTime = `${currentDayOfWeek}, ngày ${currentDayOfMonth} ${currentMonthName} năm ${currentYear}`;


	const can = {
		event           : useCan('salesEventView'),
		commit          : useCan('salesCommitView'),
		customer        : useCan('customerView'),
		contract        : useCan('contractView'),
		revenue         : useCan('revenueView'),
		project         : useCan('projectView'),
		uploadWebsite   : useCan('uploadWebsiteView'),
		report          : true,
	}

	const ActionItem = ({name, link, icon}) => {
		return (
			<div className={cn('action-item')}>
				<Link to={link}>
					<div className={cn('icon')}><Image src={icon} /></div>
					<p className={cn('name')}>{name}</p>
				</Link>
			</div>
		)
	}

	return (
		<div className={cn('account-action')}>
			<div className={cn('account')}>
				<div className={cn('account-name')}><p>Hello, {user.lastname}</p></div>
				<p>{fullTime}</p>
			</div>
			<div className={cn('action')}>
				{ can.event && <ActionItem name={'Lịch hẹn'} icon={images.iconEvent} link={'/sale/event'} />}
				{ can.commit && <ActionItem name={'Cam kết'} icon={images.iconCommit} link={'/sale/commit'} />}
				{ can.customer && <ActionItem name={'Khách hàng'} icon={images.iconCustomer} link={'/customer'}  />}
				{ can.contract && <ActionItem name={'Hợp đồng'} icon={images.iconContract} link={'/contract'}  />}
				{ can.revenue && <ActionItem name={'Doanh thu'} icon={images.iconRevenue} link={'/revenue'}  />}
				{ can.project && <ActionItem name={'Dự án'} icon={images.iconProject} link={'/project'}  />}
				{ can.uploadWebsite && <ActionItem name={'Upload'} icon={images.iconUpload} link={'/todo/upload-website'}  />}
				{ can.report && <ActionItem name={'Báo cao'} icon={images.iconReport} link={'/report'}  />}
				<ActionItem name={'Kiểm Tra Tên Miền'} icon={images.iconDomain} link={'/tool/check-domain'}  />
				<ActionItem name={'Dự toán host'} icon={images.iconHost} link={'/tool/calculate-host'}  />
			</div>
		</div>
	)
}

export default ContentHeader;