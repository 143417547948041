import style from "../../style/SaleReport.module.scss";
import className from "classnames/bind";
import {useEffect, useState} from "react";
import _ from "lodash";
import ActionBar from "~/layout/ActionBar";
import {Col, Row} from "antd";
import {apiError, handleRequest, numberFormat} from "../../../../utils";
import {Icon, Loading} from "../../../../components";
import reportApi from "../../../../api/reportApi";
import {SaleEventTable, SaleEventSearch} from "../../components";
import {useGroup} from "../../../../hooks";
const cn = className.bind(style);
function SaleEvent() {
	const [items, setItems] = useState([]);
	const [totalData, setTotalData] = useState({
		events : [],
		consultation: [],
	});
	const [loading, setLoading] = useState(true);
	const [filter, setFilter] = useState({
		type : 'groups'
	});

	const listGroup = useGroup();

	//Load data
	useEffect(() => {
		handleLoadData().then();
	}, [filter])

	const handleLoadData = async () => {

		setLoading(true);

		let [error, response] = await handleRequest(reportApi.saleEvent(filter));

		let message = apiError(`Load data không thành công`, error, response);

		if(!message) {
			totalData.events = response.data.total.events;
			totalData.consultation = response.data.total.consultation;
			setTotalData(totalData);
			setItems(response.data.items);
		}

		setLoading(false);
	}

	//Search
	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		setFilter(newFilter);
	};

	return (
		<>
			<ActionBar title={'Báo Cáo Lịch Hẹn'}></ActionBar>
			<div className="container d-block">
				<SaleEventSearch listGroup={listGroup} onChange={handleFilterChange} />
				<div className="content">
					<Row gutter={10} className="pd-1">
						<Col span={3}>
							<div className={cn('event-item-number', 'event-item-number-green', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3 className="text-lg">Tổng hẹn</h3>
									<p>{numberFormat(totalData.events.total)}</p>
								</div>
							</div>
						</Col>
						<Col span={3}>
							<div className={cn('event-item-number', 'event-item-number-green', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3 className="text-lg">Hẹn Mới</h3>
									<p>{numberFormat(totalData.events.new)}</p>
								</div>
							</div>
						</Col>
						<Col span={3}>
							<div className={cn('event-item-number', 'event-item-number-green', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3 className="text-lg">Hẹn Cũ</h3>
									<p>{numberFormat(totalData.events.old)}</p>
								</div>
							</div>
						</Col>
						<Col span={4}>
							<div className={cn('event-item-number', 'event-item-number-red', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3 className="text-lg">Hẹn Bị Hủy</h3>
									<p>{numberFormat(totalData.events.cancel)}</p>
								</div>
							</div>
						</Col>
						<Col span={3}>
							<div className={cn('event-item-number', 'event-item-number-blue', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3 className="text-lg">Tư vấn</h3>
									<p>{numberFormat(totalData.consultation.total)}</p>
								</div>
							</div>
						</Col>
						<Col span={3}>
							<div className={cn('event-item-number', 'event-item-number-blue', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3 className="text-lg">Tư vấn mới</h3>
									<p>{numberFormat(totalData.consultation.new)}</p>
								</div>
							</div>
						</Col>
						<Col span={3}>
							<div className={cn('event-item-number', 'event-item-number-blue', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3 className="text-lg">Tư vấn củ</h3>
									<p>{numberFormat(totalData.consultation.old)}</p>
								</div>
							</div>
						</Col>
					</Row>
					{loading && <Loading />}
					{(!_.isEmpty(items) && items) && <SaleEventTable items={items} />}
				</div>
			</div>
		</>
	)
}

export default SaleEvent;