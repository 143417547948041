import {memo, useMemo, useState} from "react";
import {
	SelectField,
	SelectUserAll,
	DateRangeField,
	InputField
} from "~/components/Forms";
import StatusLabel from "~/components/StatusLabel";
import {
	useCan,
	useUtilities,
	useCurrentUser
} from "~/hooks";
import {strToTime} from "~/utils";

function ConsultationSearchBar({listGroup, filter, onChange, onSearchChange}) {

	const {utilities} = useUtilities();

	const currentUser 		= useCurrentUser();

	const [optionsSelectUser, setOptionsSelectUser] = useState([{value:'', label:'Tất cả'}]);

	const canViewAll 		= useCan('salesEventListAll');

	const canViewGroup 		= useCan('salesEventListGroup');

	const canViewLeader 	= useCan('salesEventListLeader');

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất các nhóm' }, ...Object.entries(listGroup).map(([key, group]) => {
			return {
				value: group.id,
				label: group.name
			};
		})];
	}, [listGroup]);

	let [listPosition, setListPosition] = useState(utilities.roles);

	listPosition = Object.keys(utilities.roles).filter((key) => {
		return utilities.roles[key].department == currentUser.department;
	}).reduce((obj, key) => {
		obj[key] = utilities.roles[key];
		return obj;
	}, {});

	const positionOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(listPosition).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.title}</StatusLabel>
			};
		})];
	}, [listPosition]);

	const typeOptions = [
		{value : '', label : 'Tất cả khách'},
		{value : 'new', label : 'Khách mới'},
		{value : 'old', label : 'Khách củ'},
	];

	const statusOptions = [
		{value : '', label : 'Tất cả trạng thái'},
		{value : 0, label : 'Đợi đi'},
		{value : 1, label : 'Đang gặp khách'},
		{value : 2, label : 'Hoàn thành'},
		{value : 3, label : 'Đã hủy'},
	];

	const handleKeywordChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	const handleGroupChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			group: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};

	const handleDateChange = (value) => {
		if (!onChange) return;
		let valueNew = [...value]
		valueNew[0] = strToTime(valueNew[0]);
		valueNew[1] = strToTime(valueNew[1]);
		const newFilter = {
			...filter,
			time: valueNew || undefined,
			page: 1,
		};
		onChange(newFilter);
	};

	const handleUserChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			userId: value,
			page: 1,
		};
		onChange(newFilter);
	}

	const handleTypeChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			type: value,
			page: 1,
		};
		onChange(newFilter);
	}

	const handleStatusChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			status: value,
			page: 1,
		};
		onChange(newFilter);
	}

	const handleRoleChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			role: value,
			page: 1,
		};
		onChange(newFilter);
	}

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<InputField defaultValue={filter.keyword} placeholder="Số điện thoại" onChange={handleKeywordChange} />
					<DateRangeField onChange={(value) => handleDateChange(value)} />
					{(canViewAll || canViewGroup) && <SelectField placeholder="Chọn nhóm" options={groupOptions} onChange={(value) => handleGroupChange(value)} />}
					{(canViewAll || canViewGroup || canViewLeader) && <SelectUserAll value={filter.userId} placeholder="Nhân viên" options={optionsSelectUser} setOptions={setOptionsSelectUser} onChange={handleUserChange} />}
					<SelectField value={filter.type} placeholder="Loại khách hàng" options={typeOptions} onChange={(value) => handleTypeChange(value)} />
					<SelectField value={filter.status} placeholder="Trạng thái" options={statusOptions} onChange={(value) => handleStatusChange(value)} />
					<SelectField name="role" placeholder="Chọn chức vụ" options={positionOptions} onChange={(value, event) => handleRoleChange(value)} />
				</form>
			</div>
		</div>
	)
}

export default memo(ConsultationSearchBar);