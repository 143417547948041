import {memo} from "react";
import {Table} from "antd";
import {ceil} from "lodash";

function SaleEventTable({items = []}) {
	const percentEvent = (call, event) => {
		if(event == 0) return 0;
		let percent = ceil(call / event);
		return (
			<><b>{percent}</b> cuộc gọi / hẹn</>
		)
	}
	const percentEventCancel = (event, eventCancel) => {
		if(eventCancel == 0) return 0;
		let percent = ceil(eventCancel / event *100);
		return (
			<>
				<span className={((percent >= 50 && eventCancel > 10) && 'color-red')}>{percent}%</span>
			</>
		)
	}

	const percentContract = (eventSuccess, contract) => {
		if(contract == 0) return 0;
		let percent = ceil(eventSuccess / contract );
		return (
			<><b>{percent}</b> tư vấn  / hợp đồng</>
		)
	}


	const columns = [
		{ title: 'Tên', dataIndex: 'name', key: "name", width: '180px', render: (_, item) => (
			<><b>{`${item?.name}`}</b></>
		)},
		{ title: 'Hẹn', dataIndex: 'total', key: "total", width: '70px', render: (_, item) => (
			<b className="text-md text-center">{item?.events.total}</b>
		)},
		{ title: 'Hẹn mới', dataIndex: 'new', key: "new", width: '70px', render: (_, item) => (
				<>{item?.events.new}</>
			)},
		{ title: 'Hẹn củ', dataIndex: 'old', key: "old", width: '70px', render: (_, item) => (
				<>{item?.events.old}</>
			)},
		{ title: 'Hẹn bị hủy', dataIndex: 'cancel', key: "cancel", width: '70px', render: (_, item) => (
				<>{item?.events.cancel} ({percentEventCancel(item.events.total, item.events.cancel)})</>
			)},
		{ title: 'Tư vấn', dataIndex: 'consultation', key: "consultation", width: '70px', render: (_, item) => (
				<>{item.consultation.total}</>
		)},
		{ title: 'Tư vấn mới', dataIndex: 'consultationNew', key: "consultationNew", width: '70px', render: (_, item) => (
			<>{item?.consultation.new.total}</>
		)},
		{ title: 'Tư vấn củ', dataIndex: 'consultationOld', key: "consultationOld", width: '70px', render: (_, item) => (
			<>{item?.consultation.old.total}</>
		)},
		{ title: 'Bàn giao', dataIndex: 'handOver', key: "handOver", width: '70px', render: (_, item) => (
			<>{item?.consultation.handOver.total}</>
		)},
		{ title: 'Hợp đồng', dataIndex: 'contract', key: "contract", width: '200px', render: (_, item) => (
			<><b>{item.contract}</b> ({percentContract(item.consultation.total, item.contract)})</>
		)},
	];
	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} rowKey="id" dataSource={items} scroll={{ y:550, x: 'max-content' }} pagination={false} />
			</div>
		</div>
	)
}

export default memo(SaleEventTable);