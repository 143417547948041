import FontAwesomeIcon from "~/components/FontAwesome";
import _ from "lodash";
import {useCan} from "../../hooks";

export const NavBarItems = ({notificationCount}) => {

	let formTotal = 0;
	for (let prop in notificationCount.form) {
		if (notificationCount.form.hasOwnProperty(prop)) {
			formTotal += notificationCount.form[prop];
		}
	}

	let navBarList = [
		{
			key: 'home',
			title: 'Dashboard',
			icon: <FontAwesomeIcon icon="fa-light fa-house" />,
			to: "/",
			count: 0,
		},
		{
			key: 'sale',
			title: 'Kinh doanh',
			icon: <FontAwesomeIcon icon="fa-light fa-tty-answer" />,
			to: "/sale/event",
			count: 0,
			submenu: [
				{
					key: 'saleEvent',
					title: 'Lịch hẹn',
					icon: <FontAwesomeIcon icon="fa-light fa-tty-answer" />,
					to: "/sale/event"
				},
				{
					key: 'saleConsultation',
					title: 'Tư vấn',
					icon: <FontAwesomeIcon icon="fa-light fa-tty-answer" />,
					to: "/sale/consultation"
				},
				{
					key: 'saleWorking',
					title: 'Hoạt động',
					icon: <FontAwesomeIcon icon="fa-light fa-tty-answer" />,
					to: "/sale/working"
				},
				{
					key: 'saleCommit',
					title: 'Cam kết',
					icon: <FontAwesomeIcon icon="fa-light fa-tty-answer" />,
					to: "/sale/commit"
				},
				{
					key: 'saleDoc',
					title: 'Tài liệu',
					icon: <FontAwesomeIcon icon="fa-light fa-folder-open" />,
					to: "/documents"
				},
				{
					key: 'saleKpi',
					title: 'KPI',
					icon: <FontAwesomeIcon icon="fa-light fa-tty-answer" />,
					to: "/sale/kpi"
				},
			]
		},
		{
			key: 'personnelMain',
			title: 'Nhân sự',
			icon: <FontAwesomeIcon icon="fa-light fa-users-medical" />,
			to: "/personnel",
			count: 0,
			submenu: [
				{
					key: 'candidates',
					title: 'Ứng viên',
					icon: <FontAwesomeIcon icon="fa-light fa-users-medical" />,
					to: "/candidates"
				},
				{
					key: 'personnel',
					title: 'Nhân viên',
					icon: <FontAwesomeIcon icon="fa-light fa-users-medical" />,
					to: "/personnel"
				},
				{
					key: 'working-day',
					title: 'Chấm Công',
					icon: <FontAwesomeIcon icon="fa-light fa-clouds-sun" />,
					to: "/working-day"
				}
			]
		},
		{
			key: 'customer',
			title: 'Khách hàng',
			icon: <FontAwesomeIcon icon="fa-light fa-chart-user" />,
			to: "/customer",
			count: 0,
		},
		{
			key: 'service',
			title: 'Dịch vụ',
			icon: <FontAwesomeIcon icon="fa-light fa-hourglass-clock" />,
			to: "/service",
			count: 0,
			submenu: [
				{
					key: 'serviceExtend',
					title: 'Thông báo gia hạn',
					icon: <FontAwesomeIcon icon="fa-light fa-file-word" />,
					to: "/extend"
				},
				{
					key: 'serviceWillExpired',
					title: 'Sắp hết hạn',
					icon: <FontAwesomeIcon icon="fa-light fa-file-word" />,
					to: "/service/will-expired"
				},
				{
					key: 'serviceExpiry',
					title: 'Hết hạn',
					icon: <FontAwesomeIcon icon="fa-light fa-file-word" />,
					to: "/service/expiry"
				}
			]
		},
		{
			key: 'contract',
			title: 'Hợp đồng',
			icon: <FontAwesomeIcon icon="fa-light fa-file-signature" />,
			to: "/contract",
			count: 0,
		},
		{
			key: 'revenue',
			title: 'Doanh thu',
			icon: <FontAwesomeIcon icon="fa-light fa-sack-dollar" />,
			to: "/revenue",
			count: 0,
		},
		{
			key: 'expense',
			title: 'Chi Phí',
			icon: <FontAwesomeIcon icon="fa-light fa-circle-dollar" />,
			to: "/expense",
			count: 0,
		},
		{
			key: 'project',
			title: 'Dự án',
			icon: <FontAwesomeIcon icon="fa-light fa-rectangle-history-circle-plus" />,
			to: "/project",
			count: 0,
		},
		{
			key: 'task',
			title: 'Task',
			icon: <FontAwesomeIcon icon="fa-light fa-list-ul" />,
			to: "/task",
			count: notificationCount.tasks,
		},
		{
			key: 'form',
			title: 'Đơn từ',
			icon: <FontAwesomeIcon icon="fa-light fa-file-word" />,
			to: "/form",
			count: formTotal,
			submenu: [
				{
					key: 'salary',
					title: 'Ứng lương',
					icon: <FontAwesomeIcon icon="fa-light fa-file-word" />,
					to: "/form/salary",
					count: notificationCount.form.salary,
				},
				{
					key: 'sabbatical',
					title: 'Nghỉ phép',
					icon: <FontAwesomeIcon icon="fa-light fa-file-word" />,
					to: "/form/sabbatical",
					count: notificationCount.form.sabbatical,
				},
				{
					key: 'payment',
					title: 'Phiếu chi',
					icon: <FontAwesomeIcon icon="fa-light fa-file-word" />,
					to: "/form/payment",
					count: notificationCount.form.payment,
				},
				{
					key: 'papers',
					title: 'Xin giấy Tờ',
					icon: <FontAwesomeIcon icon="fa-light fa-file-word" />,
					to: "/form/papers",
					count: notificationCount.form.papers,
				},
				{
					key: 'papersNumber',
					title: 'Quản lý giấy tờ',
					icon: <FontAwesomeIcon icon="fa-light fa-file-word" />,
					to: "/form/papers-number",
					count: 0,
				},
				{
					key: 'papersType',
					title: 'Các loại giấy tờ',
					icon: <FontAwesomeIcon icon="fa-light fa-file-word" />,
					to: "/form/papers-type",
					count: 0,
				}
			]
		},
		{
			key: 'rules',
			title: 'Nội Quy',
			icon: <FontAwesomeIcon icon="fa-light fa-scale-balanced" />,
			to: "/rules",
			count: 0,
		},
		{
			key: 'pay',
			title: 'Lương',
			icon: <FontAwesomeIcon icon="fa-light fa-money-check-dollar-pen" />,
			to: "/pay",
			count: 0,
		},
		{
			key: 'report',
			title: 'Báo cáo',
			icon: <FontAwesomeIcon icon="fa-light fa-chart-user" />,
			to: "/report",
			count: 0,
		},
		{
			key: 'toDo',
			title: '',
			icon: <FontAwesomeIcon icon="fa-light fa-grid" />,
			to: "",
			count: 0,
			submenu: [
				{
					key: 'CreatedWebsite',
					title: 'Tạo Website',
					icon: <FontAwesomeIcon icon="fa-light fa-cloud-arrow-up" />,
					to: "/todo/created-website"
				},
				{
					key: 'UploadWebsite',
					title: 'Upload Website',
					icon: <FontAwesomeIcon icon="fa-light fa-cloud-arrow-up" />,
					to: "/todo/upload-website"
				},
				{
					key: 'BuyService',
					title: 'Mua dịch vụ',
					icon: <FontAwesomeIcon icon="fa-light fa-basket-shopping" />,
					to: "/todo/service-buy"
				},
				{
					key: 'serviceToDo',
					title: 'Cài đặt dịch vụ',
					icon: <FontAwesomeIcon icon="fa-light fa-file-word" />,
					to: "/todo/service-todo"
				},
				{
					key: 'checkDomain',
					title: 'Kiểm tra tên miền',
					icon: <FontAwesomeIcon icon="fa-light fa-file-word" />,
					to: "/tool/check-domain"
				},
				{
					key: 'calculateHost',
					title: 'Dự đoán host',
					icon: <FontAwesomeIcon icon="fa-light fa-file-word" />,
					to: "/tool/calculate-host"
				},


			]
		},

	];

	const sales = {
		'event'     : useCan('salesEventView'),
		'advisory'  : useCan('salesAdvisoryView'),
		'commit'    : useCan('salesCommitView'),
		'kpi'       : useCan('salesKpiView'),
		'document'       : useCan('documentsView'),
	}

	const personnel = {
		'personnel': useCan('personnelView'),
		'candidates': useCan('candidatesView'),
		'workingDay': useCan('workingDayView'),
	}

	const customer = {
		'customer': useCan('customerView'),
	}

	const contract = {
		'contract': useCan('contractView'),
	}

	const revenue = {
		'revenue': useCan('revenueView'),
	}

	const service = {
		'service': useCan('serviceView'),
		'extend': useCan('serviceExtend'),
		'willExpired': useCan('serviceWillExpired'),
		'expiry': useCan('serviceExpiry'),
	}

	const expense = {
		'expense': useCan('expenseView'),
	}

	const project = {
		'project': useCan('projectView'),
	}

	const task = {
		'task': useCan('taskView'),
	}

	const form = {
		'sabbatical': useCan('formSabbaticalView'),
		'salary': useCan('formSalaryView'),
		'payment': useCan('formPaymentView'),
		'papers': useCan('papersView'),
		'papersNumber': useCan('papersNumberView'),
		'papersType': useCan('papersTypeView'),
	}

	const toDo = {
		'created': useCan('createdWebsiteView'),
		'upload': useCan('uploadWebsiteView'),
		'buyService': useCan('buyServiceView'),
		'serviceToDo': useCan('serviceToDoView'),
	}

	const pay = {
		'view': useCan('payView'),
	}

	const rules = {
		'view': useCan('rules'),
	}

	const report = {
		'saleAnalytic': useCan('reportSaleAnalytic'),
		'salePoint': useCan('reportSalePoint'),
		'sale': useCan('reportSale'),
		'revenue': useCan('reportRevenue'),
		'pay': useCan('reportPay'),
		'financial': useCan('reportFinancial'),
	}

	function removeItem(navBarList, itemKey) {
		_.remove(navBarList, function(item) {
			return item.key === itemKey;
		});
	}

	function removeSubItem(navBarList, itemIndex, subItemKey) {
		_.remove(navBarList[itemIndex]?.submenu, function(item) {
			return item.key === subItemKey;
		});
	}

	if(!sales.event && !sales.kpi && !sales.commit && !sales.document) {
		removeItem(navBarList, 'sale')
	}
	else {
		_.forEach(navBarList, function(menu, index) {
			if(menu.key == 'sale') {
				if(!sales.event) {
					removeSubItem(navBarList, index, 'saleEvent')
					removeSubItem(navBarList, index, 'saleConsultation')
					removeSubItem(navBarList, index, 'saleWorking')
				}
				if(!sales.commit) {
					removeSubItem(navBarList, index, 'saleCommit')
				}
				if(!sales.kpi) {
					removeSubItem(navBarList, index, 'saleKpi')
				}
				if(!sales.document) {
					removeSubItem(navBarList, index, 'saleDoc')
				}
			}
		});
	}

	if(!personnel.personnel && !personnel.candidates && !personnel.workingDay) {
		removeItem(navBarList, 'personnelMain')
	}
	else {
		_.forEach(navBarList, function(menu, index) {
			if(menu.key == 'personnelMain') {
				if(!personnel.personnel) {
					removeSubItem(navBarList, index, 'personnel')
				}
				if(!personnel.candidates) {
					removeSubItem(navBarList, index, 'candidates')
				}
				if(!personnel.workingDay) {
					removeSubItem(navBarList, index, 'working-day')
				}
			}
		});
	}

	if(!customer.customer) {
		removeItem(navBarList, 'customer')
	}
	if(!contract.contract) {
		removeItem(navBarList, 'contract')
	}
	if(!revenue.revenue) {
		removeItem(navBarList, 'revenue')
	}
	if(!expense.expense) {
		removeItem(navBarList, 'expense')
	}
	if(!project.project) {
		removeItem(navBarList, 'project')
	}
	if(!task.task) {
		removeItem(navBarList, 'task')
	}

	if(!service.service && !service.expiry && !service.extend && !service.willExpired) {
		removeItem(navBarList, 'service')
	}
	else {
		_.forEach(navBarList, function(menu, index) {
			if(menu.key == 'service') {
				if(!service.extend) {
					removeSubItem(navBarList, index, 'serviceExtend')
				}
				if(!service.expiry) {
					removeSubItem(navBarList, index, 'serviceExpiry')
				}
				if(!service.willExpired) {
					removeSubItem(navBarList, index, 'serviceWillExpired')
				}
			}
		});
	}

	if(!form.sabbatical && !form.salary && !form.payment && !form.papers && !form.papersNumber && !form.papersType) {
		removeItem(navBarList, 'form')
	}
	else {
		_.forEach(navBarList, function(menu, index) {
			if(menu.key == 'form') {
				if(!form.sabbatical) {
					removeSubItem(navBarList, index, 'sabbatical')
				}
				if(!form.salary) {
					removeSubItem(navBarList, index, 'salary')
				}
				if(!form.payment) {
					removeSubItem(navBarList, index, 'payment')
				}
				if(!form.papers) {
					removeSubItem(navBarList, index, 'papers')
				}
				if(!form.papersNumber) {
					removeSubItem(navBarList, index, 'papersNumber')
				}
				if(!form.papersType) {
					removeSubItem(navBarList, index, 'papersType')
				}
			}
		});
	}

	if(!toDo.upload && !toDo.buyService && !toDo.serviceToDo && !toDo.created) {
		removeItem(navBarList, 'toDo')
	}
	else {
		_.forEach(navBarList, function(menu, index) {
			if(menu.key == 'toDo') {
				if(!toDo.created) {
					removeSubItem(navBarList, index, 'CreatedWebsite')
				}
				if(!toDo.upload) {
					removeSubItem(navBarList, index, 'UploadWebsite')
				}
				if(!toDo.buyService) {
					removeSubItem(navBarList, index, 'BuyService')
				}
				if(!toDo.serviceToDo) {
					removeSubItem(navBarList, index, 'serviceToDo')
				}
			}
		});
	}

	if(!pay.view) {
		removeItem(navBarList, 'pay')
	}

	if(!rules.view) {
		removeItem(navBarList, 'rulesView')
	}

	if(!report.sale
		&& !report.salePoint
		&& !report.saleAnalytic
		&& !report.pay
		&& !report.revenue
		&& !report.financial ) {
		removeItem(navBarList, 'report')
	}

	_.forEach(navBarList, function(menu, index) {
		if (menu?.submenu && menu?.submenu.length === 1) {
			navBarList[index].title = menu?.submenu[0].title;
			navBarList[index].to = menu?.submenu[0].to;
			navBarList[index].icon = menu?.submenu[0].icon;
			navBarList[index].submenu = undefined;
		}
	});

	return navBarList;
}